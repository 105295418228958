var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createSelector } from 'reselect';
import { appServicesRootSelector } from '../app-services-root-selector';
import { APP_SERVICES_REDUCER_NAMESPACES } from '../constants';
import { getFormattedDateWithoutTimeZone } from 'common/helpers/get-formatted-date';
import { getFormattedPhone } from 'common/helpers/get-formatted-phone';
import { pick } from 'common/helpers/pick';
var rootName = APP_SERVICES_REDUCER_NAMESPACES.common;
var stateName = APP_SERVICES_REDUCER_NAMESPACES.profile;
export var profileServiceRootSelector = createSelector(appServicesRootSelector, function (state) { return state && state[stateName]; });
export var profileServiceDataSelector = createSelector(profileServiceRootSelector, function (state) { return state && state.data; });
export var profileServiceAvailablePagesSelector = createSelector(profileServiceRootSelector, function (state) {
    return state &&
        state.data &&
        pick(state.data, ['isOrganizationStructureAvailable', 'myDevelopmentSectionEnabled', 'showSurpriseBoxes']);
});
export var profileServiceBalanceAndNotificationsMetaDataSelector = createSelector(profileServiceRootSelector, function (state) { return state && state.balanceAndNotificationsData; });
export var profileServiceMainDataSelector = createSelector(profileServiceDataSelector, function (profileData) {
    var _a;
    return profileData
        ? {
            firstName: profileData.firstName,
            lastName: profileData.lastName,
            fullName: profileData.fullName,
            middleName: profileData.middleName,
            personnelNumber: profileData.personnelNumber,
            image: profileData.image,
            tealBalance: profileData.tealBalance,
            thankBalance: profileData.thankBalance,
            position: (_a = profileData.position) === null || _a === void 0 ? void 0 : _a.name,
        }
        : {
            firstName: '',
            lastName: '',
            middleName: '',
            personnelNumber: '',
            fullName: '',
            image: null,
            tealBalance: 0,
            thankBalance: 0,
        };
});
export var profileServiceSecondaryDataSelector = createSelector(profileServiceDataSelector, function (profileData) {
    return profileData
        ? {
            tags: __spreadArray(__spreadArray([], profileData.customTags, true), profileData.importedTags, true),
            hireDate: profileData.hireDate && getFormattedDateWithoutTimeZone(profileData.hireDate, 'P'),
            birthDate: profileData.birthDate && getFormattedDateWithoutTimeZone(profileData.birthDate, 'd MMMM'),
            probationEndDate: profileData.probationEndDate && getFormattedDateWithoutTimeZone(profileData.probationEndDate, 'P'),
            fireDate: profileData.fireDate && getFormattedDateWithoutTimeZone(profileData.fireDate, 'P'),
            phone: profileData.phone && getFormattedPhone(profileData.phone),
            email: profileData.email,
            socialNetworks: profileData.socialNetworks,
        }
        : null;
});
export var profileServiceEmailSelector = createSelector(profileServiceDataSelector, function (profileData) { var _a; return (_a = profileData === null || profileData === void 0 ? void 0 : profileData.email) !== null && _a !== void 0 ? _a : ''; });
export var profileServiceUserIdSelector = function (_a) {
    var _b;
    var _c = rootName, state = _a[_c];
    return ((_b = state[stateName].data) === null || _b === void 0 ? void 0 : _b.id) || null;
};
export var profileServiceUserHasNewNotificationsSelector = function (_a) {
    var _b = rootName, state = _a[_b];
    return state[stateName].balanceAndNotificationsData.hasNewNotifications;
};
export var profileServiceUserHasUnreadNotificationsSelector = function (_a) {
    var _b = rootName, state = _a[_b];
    return state[stateName].balanceAndNotificationsData.hasUnreadNotifications;
};
export var profileServiceIsAuthSelector = function (_a) {
    var _b;
    var _c = rootName, state = _a[_c];
    return Boolean((_b = state[stateName].data) === null || _b === void 0 ? void 0 : _b.id);
};
export var profileServiceLoadingSelector = function (_a) {
    var _b = rootName, state = _a[_b];
    return Boolean(state[stateName].loading);
};
export var profileServiceInitializedSelector = function (_a) {
    var _b = rootName, state = _a[_b];
    return Boolean(state[stateName].initialized);
};
export var profileServiceTealBalanceSelector = function (_a) {
    var _b;
    var _c = rootName, state = _a[_c];
    return Number((_b = state[stateName].balanceAndNotificationsData) === null || _b === void 0 ? void 0 : _b.tealBalance);
};
export var profileServiceThanksBalanceSelector = function (_a) {
    var _b;
    var _c = rootName, state = _a[_c];
    return Number((_b = state[stateName].balanceAndNotificationsData) === null || _b === void 0 ? void 0 : _b.thankBalance);
};
export var profileServiceThanksBalancesSumSelector = function (_a) {
    var _b, _c;
    var _d = rootName, state = _a[_d];
    return Number((_b = state[stateName].balanceAndNotificationsData) === null || _b === void 0 ? void 0 : _b.thankBalance) +
        Number((_c = state[stateName].balanceAndNotificationsData) === null || _c === void 0 ? void 0 : _c.tealBalance);
};
export var profileServiceIdSelector = function (_a) {
    var _b;
    var _c = rootName, state = _a[_c];
    return ((_b = state[stateName].data) === null || _b === void 0 ? void 0 : _b.id) || '';
};
export var profileServiceCompanyIdSelector = function (_a) {
    var _b;
    var _c = rootName, state = _a[_c];
    return ((_b = state[stateName].data) === null || _b === void 0 ? void 0 : _b.company.id) || '';
};
export var profileServiceIsUseTechSelector = function (_a) {
    var _b;
    var _c = rootName, state = _a[_c];
    return Boolean(((_b = state[stateName].data) === null || _b === void 0 ? void 0 : _b.company.id) === 'b9f28dcc-ff5e-456f-9266-031e54ae670a');
}; // USETECH company id
export var profileServiceCompanyNameSelector = function (_a) {
    var _b;
    var _c = rootName, state = _a[_c];
    return ((_b = state[stateName].data) === null || _b === void 0 ? void 0 : _b.company.name) || '';
};
export var profileServiceMyDevelopmentSectionEnabledSelector = function (_a) {
    var _b;
    var _c = rootName, state = _a[_c];
    return Boolean((_b = state[stateName].data) === null || _b === void 0 ? void 0 : _b.myDevelopmentSectionEnabled);
};
export var profileServiceShowSurpriseBoxesSelector = function (_a) {
    var _b;
    var _c = rootName, state = _a[_c];
    return Boolean((_b = state[stateName].data) === null || _b === void 0 ? void 0 : _b.showSurpriseBoxes);
};
